export const displayAlert = {
  data () {
    return {
      alert: false,
      alertType: 'warning',
      alertMessage: '',
      errors: [],
      successes: [],
      messages: [],
      timeout: 2000,
      nextFn: null,
      attrsError: false
    }
  },
  watch: {
    errors: {
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          this.emitAlert(true, 'warning', `Please correct the following errors: ${[...this.errors].join(', ')}`)
        }
      },
      deep: true
    },
    successes: {
      handler (newValue) {
        if (newValue && newValue.length > 0) {
          this.emitAlert(true, 'success', `Success: ${[...this.successes].join(', ')}`)
        }
      },
      deep: true
    }
  },
  methods: {
    emitAlert (showAlert, alertType, alertMessage, messages = [], attrsError = false) {
      const payload = {
        alert: showAlert,
        alertType: alertType,
        alertMessage: alertMessage,
        messages: messages,
        attrsError: attrsError
      }
      this.$root.$emit('alertdialog', payload)
    },
    resetAlert () {
      this.errors.length = 0
      this.successes.length = 0
      const payload = {
        alert: false,
        alertType: 'success',
        alertMessage: '',
        messages: [],
        attrsError: false
      }
      this.$root.$emit('alertdialog', payload)
    },
    setNextFn (fn) {
      this.$root.$emit('nextFn', fn)
    },
    handleError (err) {
      console.error(err)
      let errMessage = err.message || 'An error has occurred'

      if (errMessage.toLowerCase().includes("network error")) {
        const regex = /network error.*/i      // Remove 'Network Error' from message. Replace with 'Please refresh the page'
        errMessage = errMessage.replace(regex, "Please refresh the page.")
        console.error("Unable to connect to backend. Page refresh may be needed.")
      }

      if (err.response && err.response.data) {
        let { message, detail, errors } = err.response.data

        if (detail && typeof detail === 'object') {
          detail = detail.msg || detail.message || JSON.stringify(detail)
        }
        if (errors) {
          errors = `Errors: ${[...errors].join(', ')}`
        }
        if (!message && !detail && !errors) {
          errMessage = `${errMessage}: ${JSON.stringify(err.response.data)}`
        } else {
          errMessage = `${[message, detail, errors].filter(v => v != null).join(', ')}`
        }
      }
      this.emitAlert(true, 'warning', errMessage)
    }
  }
}
